/* Home.css */
.containerAbout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(219, 228, 245);
  padding-top: 100px;
  padding-bottom: 100px;
  color: white;
  background-image: url('../assets/aboutus.jpg');
  background-size:cover; /* Make sure the image covers the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

.paragraphAbout {
  font-size: 25px;
  /* font-family: Arial, sans-serif; */
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.aboutusContent {
  width: 50%;
  margin-right: 20px;
}

.aboutHeader {
  color: white;
  font-size: 2rem;
}

.carousel-container {
  width: 45%;
}

.carousel-image {
  width: 100%;
  height: 400px;
  object-fit: contain;
  border-radius: 10px;
}

/* Responsive Design for Mobile */
@media screen and (max-width: 768px) {
  .containerAbout {
    flex-direction: column;
    padding: 20px;
  }

  .aboutusContent {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }

  .carousel-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .aboutHeader {
    font-size: 1.5rem;
  }

  .carousel-image {
    height: 300px;
  }

  .paragraphAbout {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .aboutHeader {
    font-size: 2rem;
  }

  .carousel-image {
    height: 250px;
  }

  .paragraphAbout {
    font-size: 14px;
  }
}
