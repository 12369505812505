.containerHome {
  background-image: url('../assets/solarOne.jpg');
  background-size:cover; /* Make sure the image covers the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  height: 80vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: self-start;
  color: black; /* Text color for better visibility on image */
}
.bodyContainer{
  background-color: white;
  /* border-radius: 10px; */
  margin-top: 180px;
}
.headerTitle{
  color: black; 
  font-size: 3rem;
  padding-left: 10%;
  padding-right: 10%;
}
.paragraphHome {
  font-size: 1.2rem;
  padding-left: 10%;
  padding-right: 10%;

}
.headerContainer{
  display: none;
}

@media screen and (max-width: 768px) {
  .headerContainer{
    display: none;
    font-size: 10px;
  }
}

/* Responsive Design for Mobile */
@media screen and (max-width: 480px) {
  .headerContainer{
    display: inline-block;
    background-color: white;
  }
  .headerText{
    font-size: 2rem;
    color: black;
  }
}
