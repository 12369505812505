/* Base styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.fixed-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
}

.nav-header-text {
  margin-left: 20px;
}

/* Default for larger screens */
.nav-header-ul {
  margin-right: 20px;
  display: flex; /* Ensure it's flex for larger screens */
}

/* Hide the ul in screens smaller than 486px */
@media (max-width: 486px) {
  .fixed-nav {
    display: none; /* Hide the ul on mobile view */
  }
}

.fixed-nav ul {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  display: flex;
  justify-content: center;
}

.fixed-nav ul li {
  float: left;
}

.fixed-nav ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.fixed-nav ul li a:hover {
  background-color: #111;
}

.fixed-nav ul li.active a {
  background-color: #555;
}

.section {
  padding: 20px;
}
.sectionBottom {
  padding: 0px;
}
