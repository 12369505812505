/* Services.css */
.containerServices {
  background-image: url('https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c29sYXIlMjBwYW5lbHxlbnwwfHwwfHx8MA%3D%3D');
  background-size:cover; /* Make sure the image covers the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(243, 228, 214);
  color: black;
  text-align: center;
  padding: 20px;
}

.innerContainerServices {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
}

.textServices{
  color: white;
  font-size: 2rem;
}

.bodyContainerServices, .bodyContainerServiceMiddle {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  width: 250px; /* Set a fixed width for consistency */
  text-align: center;
}

.headerTitleServices {
  color: black;
  font-size: 1.2rem;
  margin: 10px 0;
}

.paragraphHomeServices {
  font-size: 1rem;
}

.serviceImage {
  width: 100%;
  height: auto;
  max-width: 200px; /* Ensures a maximum size */
  border-radius: 20px;
  margin-bottom: 10px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .innerContainerServices {
    flex-direction: row;
    align-items: center;
  }
  
  .bodyContainerServices, .bodyContainerServiceMiddle {
    width: 100%; /* Take full width on mobile */
  }
}

@media (max-width: 480px) {
  .headerTitleServices {
    font-size: 1rem;
  }
  
  .paragraphHomeServices {
    font-size: 0.9rem;
  }

  .serviceImage {
    max-width: 150px; /* Smaller images for small screens */
  }
  .innerContainerServices {
    flex-direction: row;
  }
}
