.back-to-top-button {
    position: fixed;
    bottom: 80px;
    right: 30px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s, transform 0.3s;
    font-size: 18px;
    z-index: 1000;
    display: none; 
  }
  
  .back-to-top-button.visible {
    display: block;
  }
  
  .back-to-top-button:hover {
    transform: scale(1.1);
    background-color: blue;
  }
  