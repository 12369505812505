/* Home.css */
.containerTestimonials {
  background-color: rgb(243, 228, 214);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.headerTestimonials {
  font-size: 2rem;
  font-family: Arial, sans-serif;
}
.paragraphTestimonials {
  font-size: 18px;
  font-family: Arial, sans-serif;
}
.carousel-container-t {
  width: 800px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.carousel-image-t {
  width: 100%;
  height: 300px;
  object-fit:contain;
  border-radius: 10px;
}
/* .carousel-image-container{
  width: 30%;
  height: 30px;
} */

/* Responsive Design for Tablets and Smaller Screens */
@media screen and (max-width: 768px) {
  .carousel-container-t {
    width: 80%; /* Reduce the width for tablets */
    padding-bottom: 20px;
  }

  .carousel-image-t {
    height: 250px; /* Reduce image height */
  }

  .testimonialHeader {
    font-size: 3rem; /* Reduce font size for smaller screens */
  }
}

/* Responsive Design for Mobile */
@media screen and (max-width: 480px) {
  .carousel-container-t {
    width: 100%; /* Full width on mobile */
    padding-bottom: 20px;
  }

  .carousel-image-t {
    height: 200px; /* Further reduce image height */
  }

  .testimonialHeader {
    font-size: 2rem; /* Smaller font size for mobile */
  }
  
}
