.containerFaqs {
  display: flex;
  justify-content: center;
  width: auto;
}
.bodyContainerFaqs {
  background-color: #ede24c;
  background-image: url('../assets/faqsTwo.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  padding-bottom: 10px;
}
.headerTitleFaqs {
  color: white;
  font-size: 2rem;
  /* padding-left: 10%;
  padding-right: 10%; */
  /* align-items: center; */
  text-align: center;
}
.paragraphFaqs {
  font-size: 1.2rem;
  padding-left: 10%;
  padding-right: 10%;
  color: white;
}
.headerContainerFaqs {
  display: none;
}
.paragraphFaqsAnswers{
  font-size: 1.2rem;
  padding-left: 13%;
  padding-right: 10%;
  color: white;
}

@media screen and (max-width: 768px) {
  .headerContainerFaqs {
    display: none;
    font-size: 10px;
  }
}

/* Responsive Design for Mobile */
@media screen and (max-width: 480px) {
  .headerContainerFaqs {
    display: inline-block;
    background-color: white;
  }
  .headerTextFaqs {
    font-size: 2rem;
    color: white;
  }
  .paragraphFaqs {
    font-size: 1rem;
    color: white;
  }
  .paragraphFaqsAnswers{
    font-size: 1rem;
  }
}