/* Contact.css */
.containerContact {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  padding: 10px 0;
}

.logoContainer {
  text-align: center;
  /* margin-bottom: 20px; */
}

.logoImage {
  width: 90px;
  height: 90px;
}

.bodyConatiner {
  text-align: center;
  background-color: black;
  padding: 20px 0;
  margin-bottom: 0;
}

.contactDetails {
  background-color:rgb(197,222,240) ;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
}

.paragraphHeader {
  font-size: 20px;
  font-family: Arial, sans-serif;
  color: white;
}
.paragraphHeaderOne {
  font-size: 20px;
  font-family: Arial, sans-serif;
  color: black;
}

.paragraphBody {
  font-size: 12px;
  font-family: Arial, sans-serif;
  color: white;
}

.paragraphBodyTwo {
  font-size: 20px;
  font-family: Arial, sans-serif;
  color: black;
}

.paragraphTittle{
  font-size: 12px;
  font-family: Arial, sans-serif;
  color: white;
}

.paragraphTittleThree {
  font-size: 20px;
  font-family: Arial, sans-serif;
  color: black;
}

.mapContainer {
  width: 100%;
  margin: 0 auto;
}

.mapContainer iframe {
  width: 100%;
  height: 380px;
  border: 0;
}

.contactUsHeader {
  text-align: center;
  font-size: 2rem;
}

.contactContainer {
  /* display: flex; */
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
  align-items: center;
  justify-content: space-between;
  padding: 0 7%;
  margin: 20px 0;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.whatsappButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366; /* WhatsApp green */
  color: white;
  padding: 15px;
  border-radius: 2%;
  text-decoration: none;
  font-size: 20px;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.9);
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  animation: blink 1s infinite; /* Apply the blink animation */
}

.whatsappButton:hover {
  background-color: #20b359; /* Change color on hover */
}

.whatsappIcon {
  margin-right: 10px;
}
.whatsappText{
  display: inline-block;}

/* Adjustments for smaller screens (tablets) */
@media screen and (max-width: 768px) {
  .contactContainer {
    flex-direction: column; /* Stack elements vertically */
    padding: 0 5%;
  }

  .mapContainer iframe {
    height: 300px; /* Adjust map height */
  }

  .contactUsHeader {
    font-size: 2rem;
  }

  .paragraphHeader, .paragraphHeaderOne {
    color: black;
    font-size: 18px;
  }

  .paragraphBody, .paragraphTittle {
    font-size: 14px;
    color: white;
  }

  .paragraphTittleThree {
    font-size: 14px;
    color: black;
  }

  .paragraphBodyTwo {
    font-size: 20px;
    color: white;
  }
}

/* Adjustments for smaller screens (mobile) */
@media screen and (max-width: 480px) {
  .contactContainer {
    flex-direction: column; /* Stack elements vertically */
    padding: 0 2%;
  }

  .mapContainer iframe {
    height: 250px; /* Reduce map height */
  }

  .contactUsHeader {
    font-size: 1.8rem; /* Reduce header size */
  }

  .paragraphHeader{
    font-size: 16px; /* Reduce text size */
    color: white;

  }
  .paragraphHeaderOne {
    font-size: 16px; /* Reduce text size */
    color: black;
  }

  .paragraphBody, .paragraphTittle {
    font-size: 12px; /* Adjust font size */
    color: white;
  }

  .paragraphTittleThree {
    font-size: 12px; /* Adjust font size */
    color: black;
  }

  .paragraphBodyTwo {
    font-size: 12px; /* Adjust font size */
    color: black;
  }

  .whatsappText{
    font-size: 12px;
  /* display: none; */
  }


  .whatsappButton {
    bottom: 20px; /* Distance from the bottom of the screen */
    right: 20px; /* Distance from the right of the screen */
    padding: 12px;
    border-radius: 2%;
    text-decoration: none;
    font-size: 12px;
    border-radius: 10px;
  }
}
